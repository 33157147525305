const menuToggler = document.querySelector('#menu-toggle');
const megaMenu = document.querySelector('#mega-menu');
const topBar = document.querySelector('#topbar');
const hamburgerContainer = document.querySelector('#hamburger-container');

const Mainmenu = {
	registerEvents: () => menuToggler.addEventListener('click', Mainmenu.toggleMainMenu),

	toggleMainMenu: () => {
		if (hamburgerContainer.classList.contains('open')) {
			hamburgerContainer.classList.remove('open');
			megaMenu.classList.remove('active');
			topBar.classList.remove('active');
			document.querySelector('html > body').classList.remove('menuactive');
		} else {
			hamburgerContainer.classList.add('open');
			megaMenu.classList.add('active');
			topBar.classList.add('active');
			document.querySelector('html > body').classList.add('menuactive');
		}
		Mainmenu.ariaUpdate();
	},

	ariaUpdate: () => {
		let menuTogglerAria = menuToggler.getAttribute('aria-expanded');
		let menuAria = megaMenu.getAttribute('aria-hidden');

		menuTogglerAria = menuTogglerAria === 'true' ? 'false' : 'true';
		menuAria = menuAria === 'true' ? 'false' : 'true';

		menuToggler.setAttribute('aria-expanded', menuTogglerAria);
		megaMenu.setAttribute('aria-hidden', menuAria);
	},

	menuAccordionToggle: (item) => {
		item.classList.contains('active')
			? item.classList.remove('active')
			: item.classList.add('active');
		let subMenu = item.nextElementSibling;

		if (subMenu.style.maxHeight) {
			subMenu.style.maxHeight = null;
		} else {
			subMenu.style.maxHeight = subMenu.scrollHeight + 'px';
		}
	},

	menuAccordion: () => {
		let accordionHeading = document.querySelectorAll('ul.main-menu > li > a');

		if (window.innerWidth < 1023) {
			accordionHeading.forEach((item, key) => {
				const toggle = document.createElement('button');
				toggle.setAttribute('aria-label', 'Åpne undermeny');
				toggle.classList.add('icon-plus');
				toggle.classList.add('expand-mobile-submenu');

				item.parentNode.insertBefore(toggle, item.nextSibling);

				toggle.addEventListener('click', (e) => {
					e.preventDefault();
					Mainmenu.menuAccordionToggle(toggle);
				});
			});
		}
	},

	init: () => {
		Mainmenu.registerEvents();
		Mainmenu.menuAccordion();

		document.addEventListener('keyup', (event) => {
			if (event.keyCode === 27) {
				if (document.querySelector('body').classList.contains('menuactive'))
					menuToggler.click();
			}
		});
	},
};

export default Mainmenu;
