// Import external modules
import React from 'react';

const ImageNav = (props) => {
	const color = props.color ? props.color : 'default-color';

	return (
		<nav className="image-zoom__slider-nav">
			<button
				ref={props.prev}
				aria-label="Previous image"
				className={`icon-arrow-left arrow-link image-zoom__slider-nav__btn image-zoom__slider-nav__btn--prev ${color}`}
				onClick={() => props.swiper.current.swiper.slidePrev()}
			></button>
			<button
				ref={props.next}
				aria-label="Next image"
				className={`icon-arrow-right arrow-link image-zoom__slider-nav__btn image-zoom__slider-nav__btn--next ${color}`}
				onClick={() => props.swiper.current.swiper.slideNext()}
			></button>
		</nav>
	);
};

export default ImageNav;
