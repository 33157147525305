
const GoogleTranslate = {

    registerEvents: () => {
        const GToggler = document.getElementById('google_translate_element');
        const textWrapper = GToggler.querySelector('span');

        GToggler.addEventListener('click', function (e) {
            e.preventDefault();
            this.classList.toggle('active');
            GoogleTranslate.toggleText(this, textWrapper);
        });

        jQuery('body').on('click', function (e) {
            let gtButton = e.target.closest('#google_translate_element');
            if (!gtButton) {
                GoogleTranslate.doChangeText(textWrapper, 'Språk');
                GoogleTranslate.gtButtonToggle(GToggler, 'block');
            }
        });
    },

    toggleText: (elm, textWrapper) => {
        let currentText = textWrapper.innerHTML,
            newText = elm.dataset.toggletext,
            initialText = elm.dataset.initialtext,
            finalText = '',
            display = '';

        if (currentText === initialText) {
            finalText = newText;
            display = 'none';
        } else {
            finalText = initialText;
            display = 'block';
        }

        GoogleTranslate.doChangeText(textWrapper, finalText);
        GoogleTranslate.gtButtonToggle(elm, display);
    },

    gtButtonToggle: (elm, display) => {
        console.log(display);
        if (elm.id === 'google_translate_element')
            document.querySelector('.goog-te-gadget').style.display = display;
        return true;
    },

    doChangeText: (elm, txt) => {
        elm.innerHTML = txt;
    },

    init: () => {
        GoogleTranslate.registerEvents();
    }
}

export default GoogleTranslate;