// Import external modules
import React, { useEffect, useRef, useCallback } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Img from 'react-cool-img';

// Import internal modules
import SliderNav from './slider-nav';

// Import Swiper styles
import 'swiper/swiper.scss';

SwiperCore.use([Navigation]);

const Slider = (props) => {
	const swiperRef = useRef(null);

	const navigateSlider = useCallback((event) => {
		if (event.keyCode === 37) swiperRef.current.swiper.slidePrev();
		if (event.keyCode === 39) swiperRef.current.swiper.slideNext();
	}, []);

	useEffect(() => {
		document.addEventListener('keyup', navigateSlider, false);
	}, []);

	return (
		<>
			<Swiper
				slidesPerView={1}
				loop={true}
				initialSlide={props.active}
				className="image-zoom__image-container"
				ref={swiperRef}
			>
				{props.images.map((image, index) => {
					return (
						<SwiperSlide
							key={`${props.id}-${index}`}
							className="image-zoom__image-wrapper"
						>
							<Img
								placeholder={image.sizes.medium}
								src={image.url}
								alt={image.alt}
								cache
							/>
						</SwiperSlide>
					);
				})}
			</Swiper>
			<SliderNav color="white" swiper={swiperRef} />
		</>
	);
};

export default Slider;
