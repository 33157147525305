// Import external modules
import React, { useState, useRef } from 'react';
import { __ } from '@wordpress/i18n';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Img from 'react-cool-img';

// Import internal modules
import ImageZoom from './image-zoom';
import SliderNav from './image-zoom/components/slider-nav';

// Import Swiper styles
import 'swiper/swiper.scss';

SwiperCore.use([Navigation]);

const MediaGallery = (props) => {
	const galleryData = window[props.id.replace(/-/g, '_')];

	const [zoom, setZoom] = useState(false);
	const [active, setActive] = useState(null);
	const [images, SetImages] = useState(galleryData);

	const handleZoom = (state, index = null) => {
		setZoom(state);
		setActive(index);
	};

	const swiperRef = useRef(null);

	return (
		<>
			<Swiper
				className="gallery-slider"
				spaceBetween={32}
				autoHeight={true}
				ref={swiperRef}
				breakpoints={{
					// Breakpoints are desktop first, and go down
					480: {
						slidesPerView: 1,
					},
					768: {
						slidesPerView: 2,
						autoHeight: false,
					},
					980: {
						slidesPerView: 3,
						autoHeight: false,
					},
				}}
			>
				{images.map((image, index) => {
					return (
						<SwiperSlide
							key={`${props.id}-${index}`}
							className="gallery-slider__slide"
						>
							<a
								className="gallery-slider__slide__link"
								onClick={() => handleZoom(true, index)}
							>
								<Img
									placeholder={image.sizes.medium}
									src={image.sizes.large}
									alt={image.alt}
									cache
								/>
							</a>
						</SwiperSlide>
					);
				})}
			</Swiper>

			<div className="gallery-slider-footer">
				<SliderNav swiper={swiperRef} />
				<a className="link-with-icon" onClick={() => handleZoom(true, null)}>
					{__('Bildeoversikt', 'dc')}
					<span aria-label="Grid view" className="icon icon-grid"></span>
				</a>
			</div>

			{zoom && <ImageZoom active={active} images={images} zoom={handleZoom}></ImageZoom>}
		</>
	);
};

export default MediaGallery;
