// Import external modules
import React from 'react';

const Grid = (props) => {
	const handleActiveChange = (index) => {
		props.onActiveChange(index);
	};

	return (
		<div className="image-zoom__grid-container">
			{props.images.map((image, index) => {
				return (
					<div
						key={`${props.id}-${index}`}
						className="image-zoom__grid-container__image"
					>
						<a onClick={() => handleActiveChange(index)}>
							<img src={image.sizes.medium} alt={image.alt} />
						</a>
					</div>
				);
			})}
		</div>
	);
};

export default Grid;
