import Mainmenu from './components/mainmenu';
Mainmenu.init();

import ToggleContent from './components/toggleContent';
ToggleContent.init();

import Shortcuts from './components/shortcuts';
Shortcuts.init();

import GoogleTranslate from './components/googleTranslate';

import './components/accordion';


const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

if (isSafari) {
	const FakeToggler = document.querySelector('.google-translate-fake-button');
	FakeToggler.parentNode.removeChild(FakeToggler);

	window.addEventListener('load', function () {
		new google.translate.TranslateElement({
			pageLanguage: 'no',
			layout: google.translate.TranslateElement.InlineLayout.VERTICAL,
			autoDisplay: false,
		}, 'google_translate_element');
	});

} else {
	window.addEventListener('load', function () {
		new google.translate.TranslateElement({
			pageLanguage: 'no',
			layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
			autoDisplay: false,
		}, 'google_translate_element');
	
		let myiFrame = document.querySelector('.goog-te-menu-frame');
		let doc = myiFrame.contentDocument;
		doc.body.innerHTML = doc.body.innerHTML + '<link rel="stylesheet" href="/wp-content/themes/elsikkerhetsportalen/assets/build/css/app.css">';
		
	});
	window.addEventListener('load', function () {
			GoogleTranslate.init();
	});
}

import scroll2Top from './components/scroll2Top.js';
import scroll2Post from './components/scroll2Post.js';
import searchwp from './components/searchwp.js';
	
import ReactDOM from 'react-dom';
import MediaGallery from './components/media-gallery';

document.querySelectorAll('.media-gallery').forEach((gallery) => {
	const id = gallery.id;
	const el = document.getElementById(id);
	ReactDOM.render(<MediaGallery id={id} />, el.querySelector('.gallery-container'));
});
	