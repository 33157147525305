const $search_forms = document.querySelectorAll('.search-form');

jQuery(document).on('searchwp_live_search_complete', () => {
	$search_forms.forEach((form, i) => {
		let id = form.id,
			$search_results = document.querySelectorAll('.searchwp-live-search-results'),
			$submit = $search_results[i].querySelector('.all-results');

		if (typeof $submit != 'undefined' && $submit != null) {
			$submit.setAttribute('form', id);
		}
	});
});
