// Import external modules
import React, { useState, useEffect, useCallback } from 'react';
import { __ } from '@wordpress/i18n';

// Import internal modules
import Grid from './components/grid';
import Slider from './components/slider';

// Import Swiper styles
import 'swiper/swiper.scss';

const ImageZoom = (props) => {
	const [active, setActive] = useState(props.active);

	const escZoom = useCallback((event) => {
		if (event.keyCode === 27) handleZoomClose();
	}, []);

	useEffect(() => {
		document.addEventListener('keyup', escZoom, false);
	}, []);

	const handleActive = (state) => {
		setActive(state);
	};

	const handleZoomClose = () => {
		props.zoom(false);
	};

	return (
		<>
			<div className="image-zoom">
				<div className="image-zoom__inner">
					<div className="image-zoom__top-bar">
						{active !== null ? (
							<a className="link-with-icon" onClick={() => handleActive(null)}>
								{__('Bildeoversikt', 'dc')}
								<span
									aria-label="Grid view"
									className="icon icon-grid white"
								></span>
							</a>
						) : (
							<span></span>
						)}

						<a className="link-with-icon" onClick={() => handleZoomClose()}>
							{__('Lukk', 'dc')}
							<span aria-label="Close" className="icon icon-close white"></span>
						</a>
					</div>

					{active !== null ? (
						<Slider active={active} images={props.images} />
					) : (
						<Grid images={props.images} onActiveChange={handleActive} />
					)}

					<div className="image-zoom__footer"></div>
				</div>
			</div>
		</>
	);
};

export default ImageZoom;
