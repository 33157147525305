import handorgel from "handorgel";
class Accordion {
    constructor() {
        if (
            typeof document.querySelector('.accordion') != 'undefined' &&
            document.querySelector('.accordion') != null
        ) {
            let accordion = new handorgel(document.querySelector('.accordion'), {
                multiSelectable: true,
                collapsible: true,
                keyboardInteraction: true,
                headerOpenClass: 'accordion__header--open',
                contentOpenClass: 'accordion__content--open',
                headerOpenedClass: 'accordion__header--opened',
                contentOpenedClass: 'accordion__content--opened',
            });

            Accordion.accordionDeepLink();
        }
    }

    static accordionDeepLink() {
        // deep linking - load tab on refresh
        let url = location.href.replace(/\/$/, '');
        if (location.hash) {
            const hash = url.split('#');
            const currentAccordion = document.querySelector('.accordion__header[data-hash="#' + hash[1] + '"] button');
            currentAccordion.click();
            currentAccordion.scrollIntoView();
        }

        // change url based on selected tab
        const selectAccordionList = [].slice.call(document.querySelectorAll('.accordion__header'));
        selectAccordionList.forEach((selectAccordion) => {
            selectAccordion.addEventListener('click', function () {
                let newUrl;
                const hash = selectAccordion.dataset.hash;
                newUrl = url.split('#')[0] + hash;
                history.replaceState(null, null, newUrl);
            });
        });
    }
}

new Accordion();