const shortcutsNavMenu = document.querySelectorAll('.block-section.scrollable-shortcuts header nav > ul > li');
const Shortcuts = {
    registerClick: () => {
        shortcutsNavMenu.forEach(function(elm) {
            let navLink = elm.querySelector('a');
            navLink.addEventListener('click', function (e) {
                e.preventDefault();
                let hash = this.getAttribute('href');
                let scroll_to = document.querySelector('#scrollToMe-' + hash.substring(1));
                if(typeof(scroll_to) != 'undefined' && scroll_to != null){
                    scroll_to.scrollIntoView({behavior: 'smooth', block: 'center'});
                    if (history.pushState)
                        history.pushState(null, null, hash);
                    else
                        location.hash = '#myhash';
                }
            });
        });
    },

    init: () => {
        Shortcuts.registerClick();
    }
}

export default Shortcuts;