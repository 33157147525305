const ToggleContent = {

    registerEvents: () => {
        let contentToggler = document.querySelectorAll('.content-toggler');
        contentToggler.forEach(function(elm) {
            elm.addEventListener('click', function (e) {
                e.preventDefault();
                this.classList.toggle('active');
                ToggleContent.toggleHiddenContent(this);
            });
        });
    },

    toggleHiddenContent: (elm) => {
        if ( !elm.classList.contains('no-toogle-content') ) {
            let targetID = elm.dataset.target;
            let hiddenContent = document.querySelector('#' + targetID);
            //elm.classList.toggle('active');
            hiddenContent.classList.toggle('active');
        }
        ToggleContent.toggleText(elm);
    },

    toggleText: (elm) => {
        let textWrapper = elm.querySelector('span');
        let currentText = textWrapper.innerHTML;
        let newText = elm.dataset.toggletext;
        let initialText = elm.dataset.initialtext;

        if (currentText === initialText) {
            textWrapper.innerHTML = newText;
        } else {
            textWrapper.innerHTML = initialText;
        }
    },

    init: () => {
        ToggleContent.registerEvents();
    }
}

export default ToggleContent;